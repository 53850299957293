"use client";

import Image from "next/image";
import { useIntl } from "react-intl";

const testimonialList = [
  {
    author: {
      avatarUrl: "https://i.pinimg.com/280x280_RS/78/8f/03/788f0311f79553a27796ca41a91df633.jpg",
      callSign: "Professors of Practice at JHU SAIS",
      displayName: "Dave Barno and Nora Bensahel",
    },
    body: "The Defense Futures Simulator has been an indispensable tool for our SAIS graduate class that requires teams to construct a US Defense Strategy with a five year detailed budget plan. Our student teams use the DFS to gain an in-depth understanding of the difficult decisions and trade offs for different budget scenarios and how they would shape the U.S. military for its worldwide commitments. We have been amazed at how much our students have learned using the DFS and we plan to continue to make this project the centerpiece of our course!",
  },
  {
    author: {
      avatarUrl:
        "https://img.stablecog.com/insecure/256w/aHR0cHM6Ly9iLnN0YWJsZWNvZy5jb20vN2IyNDFlZGQtOTNiOS00Njg4LTgzMzYtNmMwNmI1ZDViMGVlLmpwZWc.webp",
      callSign: "the_great",
      displayName: "Alexander III of Macedon",
    },
    body: "Here are so many worlds, and I have not yet conquered even one.",
  },
  {
    author: {
      avatarUrl: "https://www.whitehouse.gov/wp-content/uploads/2021/01/01_george_washington.jpg",
      callSign: "one_dollar_bill",
      displayName: "George Washington",
    },
    body: "To be prepared for war is one of the most effectual means of preserving peace.",
  },
  {
    author: {
      avatarUrl:
        "https://www.azquotes.com/public/pictures/authors/fb/52/fb529093708edffb9790fcc182af532d/53ccfbc6bc65f_sim%C3%B3n_bol%C3%ADvar.jpg",
      callSign: "el_libertador",
      displayName: "Simón Bolívar",
    },
    body: "Nothing is better for the cause of freedom than to be able to command.",
  },
];

export const TestimonialSection = () => {
  const intl = useIntl();
  const mediumOffsetList: number[] = [];
  const largeOffsetList: number[] = [];

  if (testimonialList.length % 2 > 0) {
    mediumOffsetList.push(Math.floor(testimonialList.length / 2));
  }

  if (testimonialList.length % 3 > 0) {
    largeOffsetList.push(
      Math.floor(testimonialList.length / 3),
      Math.floor(testimonialList.length / 3) + Math.ceil(testimonialList.length / 3),
    );
  }

  return (
    <section className="bg-white py-24 dark:bg-black sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <header className="mx-auto max-w-xl text-center">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-neutral-12 sm:text-4xl">
            {intl.formatMessage({
              defaultMessage: "We’ve worked with lots of amazing people",
              id: "x1NLE2",
            })}
          </h1>
        </header>
        <div className="mx-auto mt-16 max-w-7xl sm:mt-20">
          <div className="mx-auto flow-root max-w-2xl lg:mx-0 lg:max-w-none">
            <ul className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-0 lg:columns-3">
              {testimonialList.map((testimonial, index) => (
                <li
                  className={`pt-8 sm:inline-block sm:w-full sm:px-4 ${mediumOffsetList.includes(index) ? "md:ms-1/2" : "md:ms-0"} ${largeOffsetList.includes(index) ? "lg:ms-1/2" : "lg:ms-0"}`}
                  key={testimonial.author.callSign}
                >
                  <figure className="rounded-2xl bg-white-a-12 p-8 text-sm leading-6 shadow dark:bg-white-a-1">
                    <blockquote className="-indent-quote text-neutral-a-12">
                      <p className="before:content-open-quote after:content-close-quote">
                        {testimonial.body}
                      </p>
                    </blockquote>
                    <figcaption className="mt-6 flex items-center gap-x-4">
                      <Image
                        alt=""
                        className="size-10 rounded-full bg-neutral-3"
                        height={40}
                        src={testimonial.author.avatarUrl}
                        width={49}
                      />
                      <div>
                        <div className="font-semibold leading-tight text-neutral-a-12">
                          {testimonial.author.displayName}
                        </div>
                        <div className="leading-tight text-neutral-a-11">
                          {intl.formatMessage(
                            {
                              defaultMessage: "{callSign}",
                              id: "Hf3w0k",
                            },
                            {
                              callSign: testimonial.author.callSign,
                            },
                          )}
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
