"use client";

import dynamic from "next/dynamic";
import { useIntl } from "react-intl";

export const HowDFSWorksSection = () => {
  const intl = useIntl();
  const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

  const steps = [
    {
      description: intl.formatMessage({
        defaultMessage:
          "Anyone can create an account free of charge—all you need is a valid email address. You can save scenarios in your account and return to your work later, share scenarios to collaborate with other users, and test alternative approaches by copying scenarios and editing them.",
        id: "SDMp/e",
      }),
      title: intl.formatMessage({ defaultMessage: "Make an Account", id: "3rSss3" }),
      video: "globe.mp4",
    },
    {
      description: intl.formatMessage({
        defaultMessage:
          "To create a scenario, first select a baseline budget and strategy as a starting point and all changes made in the scenario will be relative to this baseline.",
        id: "VwUY0f",
      }),
      title: intl.formatMessage({ defaultMessage: "Select a Scenario", id: "K7D/ax" }),
    },
    {
      description: intl.formatMessage({
        defaultMessage:
          "The DFS Intelligent Assistant is the connection between strategy and budgets. It learns how your strategic preferences relate to specific budgetary decisions, and rather than manually sorting through hundreds of lines in the budget looking for items to add or cut, it automatically searches for a set of choices that correspond to your strategic preferences. It can also be used in reverse, taking a set of proposed changes to the budget and “scoring” what these changes imply in terms of shifts in strategic preferences. For those who prefer a more hands-on approach, you can skip the Intelligent Assistant entirely and build your scenario manually.",
        id: "65uj6O",
      }),
      title: intl.formatMessage({ defaultMessage: "Use the Intelligent Assistant", id: "92lVOS" }),
      video: "radar.mp4",
    },
    {
      description: intl.formatMessage({
        defaultMessage:
          "At the heart of DFS is the budget model. It estimates and aggregates the total costs and force structure implications of each change made from baseline. It includes realistic constraints on how much an item can be altered from the baseline to account for industrial base limitations and other factors.",
        id: "Yv1qjs",
      }),
      title: intl.formatMessage({ defaultMessage: "Model the Budget and Force Structure", id: "rHYhaW" }),
    },
    {
      description: intl.formatMessage({
        defaultMessage:
          "DFS is built to continuously learn and improve as it is used. As more people use the tool, the Intelligent Agent builds its understanding of the complex interrelationships at play in the strategy and budget. DFS is not limited to just the U.S. military—stay tuned as baseline data for more countries are added!",
        id: "l02q22",
      }),
      title: intl.formatMessage({ defaultMessage: "Learn and Evolve", id: "au5hSe" }),
    },
  ];

  return (
    <section className="isolate bg-white from-neutral-1 to-neutral-3 py-24 dark:bg-black">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <header className="mx-auto mb-16 max-w-2xl text-center">
          <h1 className="mb-4 text-3xl font-bold tracking-tight text-neutral-12 sm:text-4xl">
            {intl.formatMessage({
              defaultMessage: "How DFS Works",
              id: "RLVx1F",
            })}
          </h1>
        </header>
        <div className="space-y-4">
          {steps.map((step, index) => (
            <div className="flex flex-col items-center gap-4 lg:flex-row" key={step.title}>
              <div className="w-full space-y-4">
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                <h2 className="text-2xl font-semibold text-neutral-12">{`${index + 1}. ${step.title}`}</h2>
                <p className="text-lg text-neutral-10">{step.description}</p>
              </div>
              {step.video != null && typeof step.video === "string" && step.video.trim() !== "" && (
                <div className="flex w-full items-center justify-center lg:w-2/5">
                  <ReactPlayer
                    className="relative overflow-hidden p-0"
                    loop
                    muted
                    playing
                    url={step.video}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
