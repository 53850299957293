"use client";

import { ArrowRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useIntl } from "react-intl";

export const CallToActionSection = () => {
  const intl = useIntl();

  return (
    <section className="mx-auto max-w-7xl bg-white py-24 pb-0 dark:bg-black sm:px-6 sm:py-32 sm:pb-24 lg:px-8">
      <div className="relative isolate overflow-hidden bg-black-a-12 px-6 py-24 text-center dark:bg-white-a-1 sm:rounded-3xl sm:px-16 sm:shadow-2xl">
        <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-neutral-dark-12 sm:text-4xl">
          {intl.formatMessage(
            {
              defaultMessage: "Elevate your defense analysis.{lineBreak}Start using DFS today.",
              id: "pKUen5",
            },
            {
              lineBreak: <br />,
            },
          )}
        </h2>
        <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-neutral-dark-11">
          {intl.formatMessage({
            defaultMessage:
              "Get started with a free account and see how the Defense Futures Simulator can take your defense analysis to the next level.",
            id: "1DyYcQ",
          })}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            className="rounded-md bg-primary-dark-9 px-3.5 py-2.5 text-sm font-semibold text-primary-contrast shadow-sm hover:bg-primary-dark-10"
            href="/sign-up"
          >
            {intl.formatMessage({
              defaultMessage: "Get started",
              id: "/aBLH2",
            })}
          </Link>
          <Link
            className="rounded-md text-sm font-semibold leading-6 text-neutral-dark-12"
            href="https://www.aei.org/defense-futures-simulator"
            rel="noopener"
            target="_blank"
          >
            {intl.formatMessage({
              defaultMessage: "Learn more",
              id: "TdTXXf",
            })}
            <ArrowRightIcon
              aria-hidden="true"
              className="relative inline-block size-5 text-inherit -rotate-45 rtl:rotate-45 rtl:-scale-x-100"
            />
          </Link>
        </div>
        <svg
          aria-hidden="true"
          // eslint-disable-next-line tailwindcss/no-arbitrary-value
          className="absolute left-1/2 top-1/2 -z-10 hidden size-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:block"
          viewBox="0 0 1024 1024"
        >
          <circle
            cx={512}
            cy={512}
            fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
            fillOpacity="0.8"
            r={512}
          />
          <defs>
            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
              <stop stopColor="#4f46e5" />
              <stop offset={1} stopColor="#c7d2fe" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};
